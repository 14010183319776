// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getHotSearchPriview, putHotSearch } from '@/services/decoration.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'hotSearchEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      disableArry: [
        'name',
        'begin_time_end_time',
        'terms_and_conditions',
        'details',
        'shop_id',
        'deal_criteria',
        'discount_type',
        'stackable_discount',
        'item_quantity_tiers_attributes',
        'item_value_tiers_attributes'
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getHotSearchPriview(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.shop_search_word
          console.log(this.modifyData)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      // if (prop === 'poi_ids') {
      //   this.getProduct(data)
      // }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        search_word: {
          ...obj
        }
      }
      putHotSearch(this.$route.params.id, params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'hotSearchList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    afterAdd (creatData, alterData, prop) {
    },
    removeArray (createData, obj) {
    },
    checkDisable (createData) {
    },
    handleSwitchChange (value, createDate, prop) {
    }
  }
}
